import { Box } from '@mui/material';
import { Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getBinanceSymbols } from 'entities/terminal/model/selectors/get-binance-symbols.ts/get-binance-symbols';
import { getBybitSymbols } from 'entities/terminal/model/selectors/get-bybit-symbols/get-bybit-symbols';
import { getOkxSymbols } from 'entities/terminal/model/selectors/get-okx-symbols/get-okx-symbols';
import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { ActiveDeal, fetchPublicBotDeals } from 'pages/trading-bots/my-bot/helpers/fetch-all-deals';
import { getClosePrice } from 'pages/trading-bots/my-bot/helpers/get-close-price';
import { getEntryPrice } from 'pages/trading-bots/my-bot/helpers/get-entry-price';
import { getReturnPercent } from 'pages/trading-bots/my-bot/helpers/get-return-percent';
import { getReturnValue } from 'pages/trading-bots/my-bot/helpers/get-return-value';
import { getTotal } from 'pages/trading-bots/my-bot/helpers/get-total-price';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TradingStatistic } from 'widgets';
import { getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { roundValue } from 'shared/helpers';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { useSearch } from 'shared/hooks';
import { PlusWhite, SecondaryInfo } from 'shared/icons';
import {
  LabelInput,
  Loader,
  MainButton,
  RangePicker,
  Search,
  SingleSelect,
  Table,
  Text,
  Title,
  Tooltip,
} from 'shared/ui';
import { getBotStatistic } from '../api/bot-statistic';
import { createNewCopyBot } from '../api/create-copy-bot';
import { createCopyBotSubscription } from '../api/create-copy-bot-subscription';
import { getExchangeAccount } from '../api/get-exchange-account';
import { botNameValidator, connectedAccoutValidator, tradingBalanceValidator } from '../consts';
import { columnsCopyBot } from '../consts/columns-copy-bot';
import { action, button, buttonWrapper, formItem, inner, modal, modalChildren, title, titleInner, titleInnerText, titleWrapper, tooltip, topWrapper, wrapper } from '../styles';

interface CopyBotProps {
  isOpen: boolean;
  closeModal: () => void;
  isSignalSource?: boolean;
  bot: Bot;
  bots: Bot[];
}

export const CopyBot = ({
  isOpen,
  closeModal, 
  isSignalSource,
  bot,
  bots,
}: CopyBotProps) => {
  const skyrexUserUuid = getSkyrexUuid();
  
  const [form] = Form.useForm();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [botName, setBotName] = useState<string>('');
  const [botTrades, setBotTrades] = useState<ActiveDeal[]>([]);
  const [exchangeAccs, setExchangeAccs] = useState<any>([]);
  const [tradingBalance, setTradingBalance] = useState(bot.minInvestment);
  const [selectedExchange, setSelectedExchange] = useState<string>('');
  const [selectedPeriodOption, setSelectedPeriodOption] = useState<string>('summary');
  const [chartData, setChartData] = useState<[string, number][]>([]);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [tradingHistoryDates, setTradingHistoryDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([dayjs().subtract(30, 'day'), dayjs()]);
  const [isTradingHistoryLoading, setIsTradingHistoryLoading] = useState<boolean>(true);
  const [statistic, setStatistic] = useState<any>(null);
  const [exchangeCode, setExchangeCode] = useState<string>('');
  const [balance, setBalance] = useState<number>(0);
  console.log('selectedExchnage', selectedExchange, exchangeCode);

  const {
    search,
    changeSearchValue,
    filterItemsBySearch,
  } = useSearch([
    'baseSymbol',
    'quoteSymbol',
  ]);
  
  const handleSetBotName = (value: string) => {
    if (value.length > 20) {
      setBotName(value.slice(0, 20));
      return;
    }
    
    setBotName(value);
    form.setFieldValue('botName', value);
  };
  
  const handleSetTradingBalance = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    if (/^\d/.test(numericValue)) {
      setTradingBalance(numericValue);
      form.setFieldValue('tradingBalance', numericValue);
    } else if (numericValue === '') {
      setTradingBalance('');
      form.setFieldValue('tradingBalance', '');
    }
  };
  
  const handleSelectExchange = async (selected: string, option: any) => {
    setSelectedExchange(selected);
    setExchangeCode(option.exchangeCode);
    setDates(null);

    form.setFieldsValue({
      ...form.getFieldsValue(),
      selectedExchange: selected,
    });
  };

  const getExchangeAccounts = async () => {
    const exchangeDataResponse = await getExchangesIds(skyrexUserUuid ?? '');
    
    const {
      accounts,
    } = exchangeDataResponse.data;
    
    const exchangeAccountsMap = accounts
      .filter((account: ExchangeAccount) => bots.some((bot) => account.exchangeCode === bot.exchangeCode))
      .map((account: ExchangeAccount) => ({
        exchangeCode: account.exchangeCode,
        label: `${account.accountName}_${account.exchangeCode}`,
        value: account.exchangeAccountUuid,
      }));

    setExchangeAccs(exchangeAccountsMap);
  };
  
  const fetchExchangeSymbols = async () => {
    try {
      const binanceSymbols = await getExchangeSymbols('binance');
      const okxSymbols = await getExchangeSymbols('okx');
      const bybitSymbols = await getExchangeSymbols('bybit');
      dispatch(terminalActions.setBinanceSymbols(binanceSymbols));
      dispatch(terminalActions.setOkxSymbols(okxSymbols));
      dispatch(terminalActions.setBybitSymbols(bybitSymbols));
    } catch (error) {
      dispatch(terminalActions.setBinanceSymbols([]));
      dispatch(terminalActions.setOkxSymbols([]));
      dispatch(terminalActions.setBybitSymbols([]));
    }
  };
  
  useEffect(() => {
    fetchExchangeSymbols();
  }, []);
  
  useEffect(() => {
    getExchangeAccounts();
  }, []);
  
  const handleSetDates = (action: Dispatch<SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>>) => {
    return (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
      action(date);
    };
  };
  
  const getBotDealsData = async (botUuid: string) => {
    const botDeals = await fetchPublicBotDeals(botUuid, tradingHistoryDates!);
    setBotTrades(botDeals);
    setIsTradingHistoryLoading(false);
  }; 
  
  const binanceSymbols = useSelector(getBinanceSymbols);
  const okxSymbols = useSelector(getOkxSymbols);
  const bybitSymbols = useSelector(getBybitSymbols);
  
  const createCopyBot = async () => {
    const bot = bots.find((bot) => bot.exchangeCode === exchangeCode)!;
    
    try {
      const responseFromCreateCopyBot = await createNewCopyBot(
        {
          tradingAmount: tradingBalance,
          botUuid: bot.botUuid,
          exchangeCode: bot.exchangeCode,
          exchangeAccountUuid: selectedExchange,
          botName: botName, 
        },
      );
      
      if (!responseFromCreateCopyBot.success) {
        return;
      }

      const responseFromCreateBotSuccessSubscription = await createCopyBotSubscription({
        sourceUuid: responseFromCreateCopyBot.data.fields.sourceUuid,
        botUuid: responseFromCreateCopyBot.data.fields.botUuid,
      });
      
      if (!responseFromCreateBotSuccessSubscription.success) {
        return;
      }
      
      const createdCopyBotUuid = responseFromCreateCopyBot.data.fields.botUuid;
      navigate(`/trading-bots/my-bots/bot/${createdCopyBotUuid}`);
      return;
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleCreateCopyBot = () => {
    form
      .validateFields()
      .then(() => {
        createCopyBot(); 
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo);
      });
  };
  
  useEffect(() => {
    const currentExchangeCodeBot = bots.find((bot) => bot.exchangeCode === exchangeCode)!;
    getBotDealsData(currentExchangeCodeBot?.botUuid || bot.botUuid);
  }, [exchangeCode, tradingHistoryDates]);
  
  const handleSetPeriodOption = (value: string) => {
    setSelectedPeriodOption(value);
  };
  
  useEffect(() => {
    let chartData: [string, number][] = [];
    const botStatistic = statistic || bot.statistics;
    
    switch (selectedPeriodOption) {
    case 'day':
      chartData = botStatistic.dayChartData.map((element: any) => [
        element.date,
        (element.return / botStatistic.totalPnl) * botStatistic.roi,
      ]);
      break;
  
    case 'pair':
      chartData = botStatistic.pairChartData.map((element: any) => [
        element.pair,
        (element.return / botStatistic.totalPnl) * botStatistic.roi,
      ]);
      break;
  
    case 'summary':
    default:
      chartData = botStatistic.sumChartData.map((element: any) => [
        element.date,
        (element.return / botStatistic.totalPnl) * botStatistic.roi,
      ]);
      break;
    }
    
    setChartData(chartData);
  }, [selectedPeriodOption, dates, statistic]);

  const refetchBotStatistic = async () => {
    const existBot = bots.find((bot) => bot.exchangeCode === exchangeCode)! || bot;
    const statistic = await getBotStatistic(existBot?.botUuid, dates!);
    setStatistic(statistic);
  };

  useEffect(() => {
    refetchBotStatistic();
  }, [dates, exchangeCode]);

  const getAvailableAmount = async () => {
    const accounts = await getExchangeAccount(selectedExchange);
    if (!accounts?.length) {
      return;
    }

    const [account] = accounts;
    const balance = account.balances.find((balance: any) => balance.symbol === bot.quote)?.free || 0;
    setBalance(+balance);
  };

  useEffect(() => {
    if (!selectedExchange) {
      return;
    }
    
    getAvailableAmount();  
  }, [selectedExchange]);

  const statistics = (statistic || bot.statistics);
  const resultExchangeCode = (exchangeCode || bot?.exchangeCode) ?? 'binance';
  
  const TradingHistoryTable = useMemo(() => (
    <Table
      title='Trading history'
      headerBreakPoint='md'
      columns={columnsCopyBot}
      items={botTrades ? botTrades.filter(filterItemsBySearch).map((trade) => ({
        pair: {
          first: trade.baseSymbol, second: trade.quoteSymbol, 
        },
        closeDate: trade.finishedDate,
        create: trade.createdDate,
        status: trade.status,
        total: getTotal(trade, resultExchangeCode, binanceSymbols, bybitSymbols, okxSymbols),
        entryPrice: getEntryPrice(trade, resultExchangeCode, binanceSymbols, bybitSymbols, okxSymbols),
        closePrice: getClosePrice(trade, resultExchangeCode, binanceSymbols, bybitSymbols, okxSymbols),
        return: getReturnValue(trade, binanceSymbols),
        returnPercent: getReturnPercent(trade),
      })) : []}
      itemsCount={5}
      action={(
        <Box sx={action}>
          <Search
            value={search}
            onChange={changeSearchValue}
            placeholder='Search by pair'
          />

          <RangePicker
            value={tradingHistoryDates!}
            style={{
              padding: '7px 12px',
            }}
            handleSetNewDate={handleSetDates(setTradingHistoryDates)}
          />
        </Box>
      )}
    />
  ), [botTrades, search, tradingHistoryDates]);
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {!isSignalSource && (
          <Form form={form} layout='vertical'>
            <Box sx={topWrapper}>
              <Form.Item
                name='selectedExchange'
                style={formItem}
                rules={connectedAccoutValidator}
              >
                <SingleSelect
                  label='Exchange account'
                  options={exchangeAccs}
                  select={{
                    value: selectedExchange || undefined,
                    placeholder: 'Choose from connected accounts',
                    onChange: handleSelectExchange,
                  }}
                  labelRender={(props) => {
                    const [name, code] = (props.label as string || '').split('_');
                    return (
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Text>
                          {name}
                        </Text>
  
                        <Text
                          type='secondary'
                          styles={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {getCapitalizedExchangeTitle(code)}
                        </Text>
                      </Box>
                    );
                  }}
                  optionRender={(option) => {
                    const [name, code] = (option.label as string || '').split('_');
                    return (
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Text type='success'>
                          {name}
                        </Text>
  
                        <Text
                          type='secondary'
                          styles={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {getCapitalizedExchangeTitle(code)}
                        </Text>
                      </Box>
                    );
                  }}
                />
              </Form.Item>

              <Form.Item
                name='botName'
                style={formItem}
                rules={botNameValidator}
              >
                <LabelInput
                  label='Name'
                  value={botName}
                  placeholder='Name your bot'
                  onChange={handleSetBotName}
                />
              </Form.Item>
              
              <Form.Item
                name='tradingBalance'
                style={formItem}
                rules={tradingBalanceValidator(+bot.minInvestment)}
              >
                <LabelInput
                  label={`Trading amount (available: ${currencyFormatter(balance, 4)} ${bot.quote})`}
                  value={tradingBalance}
                  placeholder={`Specify trading amount. Minimum ${bot.minInvestment ?? 0}`}
                  onChange={handleSetTradingBalance}
                  suffix={(
                    <Tooltip title='This amount will be managed by bot. Make sure you have that amount on selected exchange account'>
                      {SecondaryInfo}
                    </Tooltip>
                  )}
                />
              </Form.Item>

              <Box sx={buttonWrapper}>
                <MainButton
                  type='primary'
                  icon={PlusWhite}
                  iconPosition='start'
                  styles={button}
                  onClick={handleCreateCopyBot}
                >
                  Copy Bot
                </MainButton>
              </Box>
            </Box>
          </Form>
        )}

        <Box sx={inner}>
          <Box sx={titleWrapper}>
            <Title styles={title}>
              {bot?.name}
            </Title>

            <Box sx={titleInner}>
              <Box>
                {exchangeIcons[(exchangeCode || bot.exchangeCode) as keyof IExchangeIcons]}
              </Box>

              <Text styles={titleInnerText}>
                {getCapitalizedExchangeTitle(exchangeCode || bot.exchangeCode)}
              </Text>

              <Text styles={titleInnerText}>
                {bot.quote}
              </Text>
            </Box>
          </Box>

          <TradingStatistic
            selectedQuote={bot.quote}
            disableTitle={true}
            customTitle={(
              <Title styles={{
                ...title,
                fontSize: 18,
              }}>
                Details
              </Title>
            )}
            listItems={[{
              title: 'Profit sharing fee',
              tooltip: (
                <div style={tooltip}>
                  Total lifetime profit generated from all closed deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.copyCommissionPercent,
            }, {
              title: 'Min. Trading amount',
              tooltip: (
                <div style={tooltip}>
                  Current profit or loss total for any active deals your have open.
                  Please note, this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.minInvestment ?? 0,
            }, {
              title: 'ROI',
              tooltip: (
                <div style={tooltip}>
                  Profit taken from closed deals (resets at midnight UTC+0 each day).
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: roundValue(statistics.roi, 2),
              type: 'roi',
            }, {
              title: 'Max drawdown',
              tooltip: (
                <div style={tooltip}>
                  Currently open deals. Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: roundValue(statistics.maxDrawdown, 2),
              type: 'max-drawdown',
            }, {
              title: 'Total trades',
              tooltip: (
                <div style={tooltip}>
                  Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: statistics.totalTrades,
            }]}
            chartData={chartData}
            isCopyBot={true}
            selectedSegment={selectedPeriodOption}
            setSelectedSegment={handleSetPeriodOption}
            chartAction={(
              <Box sx={action}>
                <RangePicker
                  value={dates!}
                  handleSetNewDate={handleSetDates(setDates)}
                />
              </Box>
            )}
          />

          <Box position='relative'>
            {isTradingHistoryLoading && (
              <Loader isContentOverflow={true} />
            )}
            
            {TradingHistoryTable}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
