import { CloseOutlined } from '@ant-design/icons';
import { Box, Hidden } from '@mui/material';
import { Button, Layout } from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'shared/hooks';
import {
  Burger,
  Logo,
} from 'shared/icons';
import { ChangePasswordModal } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as FailureModal,
  Onboarding,
  ConnectedExchangeAccountStatus as SuccessModal,
} from 'shared/ui/modals';
import { getInviteFriendsUrl } from '../api';
import {
  DesktopPopup,
  HorizontalMenu,
  MobilePopup,
  UserMenu,
} from '../components';
import {
  burger,
  closeIcon,
  header,
  tour,
  wrapper,
} from '../styles';

export const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isProductTourShown, setIsProductTourShown] = useState<boolean>(true);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [failureModalOpen, setFailureModalOpen] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  
  const handleCloseChangePassword = () => {
    setChangePasswordModalOpen(false);
  };
  
  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const openPopupHandler = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const closeProductTourHandler = () => {
    setIsProductTourShown(false);
  };
  
  const handleOpenSuccessModal = () => {
    setSuccessModalOpen(true);
  };
  
  const handleOpenFailureModal = () => {
    setFailureModalOpen(true);
  };

  const handleOpenOnboardingModal = () => {
    navigate(`${location.pathname}?mt=onboarding`);

    localStorage.setItem('connection_type', 'onboarding');
    localStorage.setItem('prev_location', location.pathname);
  };
  
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };
  
  const handleCloseFailureModal = () => {
    setFailureModalOpen(false);
  };

  const handleCloseOnboardingModal = () => {
    navigate(location.pathname);
    
    localStorage.removeItem('connection_type');
    localStorage.removeItem('prev_location');
  };

  const inviteFriends = async () => {
    const inviteFriendsUrlResponse = await getInviteFriendsUrl(window.location.href);
    if (!inviteFriendsUrlResponse.data?.url) {
      return;
    }

    window.open(inviteFriendsUrlResponse.data.url, '_self');
  };

  const isOpen = query.get('mt');
  const isSuccess = query.get('status');

  return (
    <Layout.Header style={header}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={wrapper}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1.5}
        >
          <Hidden lgUp>
            <div
              onClick={openPopupHandler}
              style={burger}
            >
              {Burger}
            </div>
          </Hidden>

          <Box
            display='flex'
            alignItems='center'
          >
            {Logo}
          </Box>
        </Box>

        <HorizontalMenu inviteFriends={inviteFriends} />

        <DesktopPopup
          isOpened={isPopupOpen}
          openHandler={setIsPopupOpen}
          inviteFriends={inviteFriends}
        />

        <MobilePopup
          isOpened={isPopupOpen}
          openHandler={setIsPopupOpen}
          inviteFriends={inviteFriends}
        />

        <Box
          display='flex'
          alignItems='center'
          gap={2}
        >
          {isProductTourShown && (
            <Hidden smDown>
              <Box sx={tour}>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={1}
                >
                  <Button
                    type='primary'
                    onClick={handleOpenOnboardingModal}
                  >
                    Product tour
                  </Button>

                  <CloseOutlined
                    style={closeIcon}
                    onClick={closeProductTourHandler}
                  />
                </Box>
              </Box>
            </Hidden>
          )}

          <UserMenu
            handleOpenChangePasswordModal={handleOpenChangePasswordModal}
          />
        </Box>
      </Box>

      <ChangePasswordModal
        isOpen={changePasswordModalOpen}
        handleClose={handleCloseChangePassword}
        handleSuccess={handleOpenSuccessModal}
        handleFailure={handleOpenFailureModal}
      />
      
      <SuccessModal
        buttonTitle='Okay'
        buttonStyles={{
          color: '#2ECD99',
          border: '0.5px solid #2ECD99',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        
        isOpen={successModalOpen}
        action={handleCloseSuccessModal}
        title='Your password is updated'
        description=''
      />
      
      <FailureModal
        buttonTitle='Okay'
        buttonStyles={{
          color: 'red',
          border: '0.5px solid red',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        isOpen={failureModalOpen}
        action={handleCloseFailureModal}
        title='Failed to update password'
        description=''
      />
      
      <Onboarding
        isOpen={!!isOpen}
        closeHandler={handleCloseOnboardingModal}
        isSuccess={!!isSuccess}
      />
    </Layout.Header>
  );
};
