import { Crypto } from '../crypto';
import { Exchange } from '../exchange/ui/exchange';
import { Gate } from '../gate';
import { IGetPlatformParams } from '../interfaces';

export const getPlatform = (params: IGetPlatformParams) => {
  const {
    platform,
    isModalOpen,
    returnToAllExchanges,
    handleClose,
  } = params;

  const unifiedExchanges = ['binance', 'okx', 'bybit'];

  if (unifiedExchanges.includes(platform)) {
    return (
      <Exchange
        exchangeType={platform}
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  }
  
  switch (platform) {
  case 'gate':
    return (
      <Gate
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  case 'crypto':
    return (
      <Crypto
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  default:
    return null;
  }
};
