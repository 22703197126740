import dayjs from 'dayjs';
import { getExchangeData } from 'entities/exchange/model/selectors/get-exchange-data/get-exchange-data';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentLastLoginDate, setCurrentLastLoginDate } from 'shared/helpers/storage-helper';
import { Trial } from 'shared/ui';
import { Layout } from '../../shared/layout';
import { Footer, Header } from '../../widgets';
import { IBaseLayout } from './interfaces';

export const BaseLayout = (props: IBaseLayout) => {
  const {
    children,
  } = props;

  const [isTrialModalOpened, setIsTrialModalOpened] = useState<boolean>(false);
  
  const connectedExchanges = useSelector(getExchangeData);
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = () => {
    setIsTrialModalOpened(false);
    setCurrentLastLoginDate(dayjs().format());

    if (!connectedExchanges.length) {
      navigate(`${location.pathname}?mt=onboarding`);
    }
  };

  useEffect(() => {
    const lastLoginDate = getCurrentLastLoginDate();
    if (lastLoginDate === null || lastLoginDate === 'null') {
      setIsTrialModalOpened(true);
    }
  }, []);

  return (
    <Layout
      header={(<Header />)}
      footer={(<Footer />)}
    >
      <Trial
        isOpened={isTrialModalOpened}
        closeHandler={closeModal}
      />
      
      {children}
    </Layout>
  );
};
