import { Box } from '@mui/material';
import { Form } from 'antd';
import { baseOrderTypes, conditionalPriceTypes } from 'pages/trading-bots/configurator/consts';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { formItem } from 'pages/trading-bots/configurator/styles';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { SecondaryInfo } from 'shared/icons';
import { LabelInput, SingleSelect, Text, Title, Tooltip } from 'shared/ui';
import { fetchBinanceExchangeInfo, fetchBybitExchangeInfo, fetchCryptoComExchangeInfo, fetchGateIOExchangeInfo, fetchOkxExchangeInfo, getExchanges } from '../../../api';
import { IMain } from '../interfaces';
import { inner, inputs, title, tooltipTitle, tooltipWrapper, wrapper } from '../styles';

const exchangeInfoRequests: any = {
  binance: fetchBinanceExchangeInfo,
  bybit: fetchBybitExchangeInfo,
  okx: fetchOkxExchangeInfo,
  gateio: fetchGateIOExchangeInfo,
  'crypto-com': fetchCryptoComExchangeInfo,
};

const getUniqueSymbolCodes = (allExchangeSymbols: any) => {
  const result = allExchangeSymbols.reduce((uniqueSymbols: any, symbol: any) => {
    if (!uniqueSymbols.some((s: any) => s.quoteAsset === symbol.quoteAsset)) {
      uniqueSymbols.push(symbol);
    }
    return uniqueSymbols;
  }, []);

  return result;
};

const calculateMarketOptions = (userWallet: any, uniqueSymbolCodes: any) => {
  // Создаем массив объектов, содержащих информацию о каждом токене и его балансе
  const options = uniqueSymbolCodes.map((token: any) => {
    const walletBalance = userWallet?.find((el: any) => el.symbol === token.quoteAsset)?.free || '0';
    return {
      label: `${token.quoteAsset}_${currencyFormatter(walletBalance, token.chartPrecision)}`,
      value: String(token.quoteAsset).toUpperCase(),
      balance: walletBalance,
      filter: +token?.minNotional,
      chartPrecision: token?.chartPrecision,
    };
  });

  // Сортируем массив по убыванию баланса
  const sorted = options.sort((a: any, b: any) => b.balance - a.balance);

  return sorted;
};

export const Main = (props: IMain) => {
  const {
    accounts,
    quote,
    setForm,
  } = props;

  const {
    bot,
    initialDataValue: {
      main,
    },
    setInitialDataValue,
  } = useContext(MainContext);

  const [maxActivePositions, setMaxActivePositions] = useState<string>(bot?.maxActiveDeals || '2');
  const [name, setName] = useState<string>(bot?.name || '');
  const [exchangeAccount, setExchangeAccount] = useState<string>(bot?.exchangeAccountUuid || '');
  const [markets, setMarkets] = useState<any>([]);
  const [market, setMarket] = useState<string>(bot?.quote || '');
  const [tradingAmount, setTradingAmount] = useState<string>(bot?.tradingAmount || '');
  const [baseOrderType, setBaseOrderType] = useState<string>(bot?.baseOrderType || baseOrderTypes[0].value);
  const [conditionalPriceType, setConditionalPriceType] = useState<string>(bot?.baseOrderConditionalTriggerType || conditionalPriceTypes[0].value);
  const [isMarketLoading, setIsMarketLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const changeInputValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      action(value);
      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          main: {
            ...prev.main,
            [field]: value,
          },
        };
      });
    };
  };

  const changeSelectValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      action(value);

      let type = '';
      const types = [
        'CONDITIONAL_LIMIT',
        'CONDITIONAL_MARKET',
      ];
      
      if (field === 'baseOrderType' && types.includes(value)) {
        type = conditionalPriceType;
      }

      if (field === 'conditionalPriceType' && types.includes(baseOrderType)) {
        type = value;
      }

      let chartPrecision = 0;
      let filter = main.filter;
      if (field === 'market') {
        const market = markets.find((market: any) => market.value === value);
        chartPrecision = market.chartPrecision;
        filter = market.filter;
      }

      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          main: {
            ...prev.main,
            [field]: value,
            conditionalPriceType: type,
            chartPrecision,
            filter,
          },
        };
      });
    };
  };

  const getAccountExchnageInfo = async () => {
    setIsMarketLoading(true);

    const account = accounts?.find((account) => account.exchangeAccountUuid === exchangeAccount);
    if (!account) {
      return;
    }

    const responseWithFinancialData = await getExchanges([account.exchangeAccountUuid]);
    const chosenExchange = responseWithFinancialData?.data?.accounts?.[0];
    if (!chosenExchange) {
      return;
    }

    const request = exchangeInfoRequests[account.exchangeCode.toLowerCase()];
    const response = await request();
    if (!response) {
      return;
    }

    const list = getUniqueSymbolCodes(response);
    const markets = calculateMarketOptions(chosenExchange.balances, list);
    setMarkets(markets);
    setIsMarketLoading(false);

    const market = markets.find((market: any) => {
      if (quote) {
        return market.value === quote;
      }

      return market.value === 'USDT';
    }) || markets[0];

    setMarket(market?.value);

    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        main: {
          ...prev.main,
          name: bot?.name,
          exchangeAccount: account.exchangeAccountUuid,
          market: market.value,
          baseOrderType,
          filter: market.filter,
          chartPrecision: market.chartPrecision,
          tradingAmount,
          conditionalPriceType,
          maxActivePositions,
        },
      };
    });
  };

  useEffect(() => {
    if (!accounts?.length) {
      return;
    }

    const account = accounts[0].exchangeAccountUuid;
    setExchangeAccount(account);

    const formData: any = {
      isOpened: true,
      form,
      type: 'main',
    };
    setForm(formData);
  }, [accounts]);

  useEffect(() => {
    if (!exchangeAccount) {
      return;
    }

    getAccountExchnageInfo();
  }, [exchangeAccount]);

  useEffect(() => {
    if (!bot) {
      return;
    }

    setName(bot.name);
    setMaxActivePositions(bot.maxActiveDeals || '2');
    setExchangeAccount(bot.exchangeAccountUuid || '');
    setMarket(bot.quote || '');
    setTradingAmount(bot.tradingAmount || '');
    setBaseOrderType(bot.baseOrderType || baseOrderTypes[0].value);
    setConditionalPriceType(bot.baseOrderConditionalTriggerType || conditionalPriceTypes[0].value);
    setMarkets([{
      label: `${bot.quote}_`,
      value: String(bot.quote).toUpperCase(),
      balance: 0,
      filter: 0,
      chartPrecision: 0,
    }]);

    form.setFieldsValue({
      name: bot.name,
      tradingAmount: bot.tradingAmount,
      maxActivePositions: bot.maxActiveDeals,
    });
  }, [bot]);

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        name,
        tradingAmount,
        maxActivePositions,
      }}
    >
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Title styles={title}>
            Main settings
          </Title>

          <Box sx={inputs}>
            <Form.Item
              name='name'
              style={formItem}
              rules={[{
                required: true,
                message: 'Name is required',
              }, {
                max: 20,
                message: 'Name should be less then 20 letters',
              }]}
            >
              <LabelInput
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Name
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                value={name}
                placeholder='Name your bot'
                onChange={changeInputValue(setName, 'name')}
              />
            </Form.Item>

            <div
              onClick={() => {
                if (accounts === null) {
                  navigate('/my-accounts');
                }
              }}
            >
              <SingleSelect
                disabled={!!bot}
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Exchange account
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                labelRender={(props) => {
                  const [name, code] = (props.label as string || '').split('_');
                  return (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Text>
                        {name}
                      </Text>

                      <Text
                        type='secondary'
                        styles={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCapitalizedExchangeTitle(code)}
                      </Text>
                    </Box>
                  );
                }}
                optionRender={(option) => {
                  const [name, code] = (option.label as string || '').split('_');
                  return (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Text type='success'>
                        {name}
                      </Text>

                      <Text
                        type='secondary'
                        styles={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCapitalizedExchangeTitle(code)}
                      </Text>
                    </Box>
                  );
                }}
                options={accounts?.map((account) => ({
                  label: `${account.accountName}_${account.exchangeCode}`,
                  value: account.exchangeAccountUuid,
                })) || []}
                select={{
                  value: exchangeAccount || undefined,
                  onChange: changeSelectValue(setExchangeAccount, 'exchangeAccount'),
                  placeholder: !accounts?.length ? 'Connect account' : 'Select account',
                }}
              />
            </div>

            <SingleSelect
              disabled={!!bot}
              loading={accounts?.length ? (isMarketLoading || !market) : false}
              label={(
                <Box sx={tooltipWrapper}>
                  <Text styles={tooltipTitle}>
                    Market
                  </Text>

                  <Tooltip title=''>
                    {SecondaryInfo}
                  </Tooltip>
                </Box>
              )}
              labelRender={(props) => {
                const [name, code] = (props.label as string || '').split('_');
                return (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Text>
                      {name}
                    </Text>

                    <Text type='secondary'>
                      {code}
                    </Text>
                  </Box>
                );
              }}
              optionRender={(option) => {
                const [name, code] = (option.label as string || '').split('_');
                return (
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Text type='success'>
                      {name}
                    </Text>

                    <Text type='secondary'>
                      {code}
                    </Text>
                  </Box>
                );
              }}
              options={markets}
              select={{
                value: market || undefined,
                onChange: changeSelectValue(setMarket, 'market'),
                placeholder: 'Select market',
              }}
            />
          </Box>
        </Box>

        <Box sx={inner}>
          <Title styles={title}>
            Base order
          </Title>

          <Box sx={inputs}>
            <Form.Item
              name='tradingAmount'
              style={formItem}
              rules={[{
                required: true,
                message: 'Trading amount is required',
              }, {
                validator: (_, value) => {
                  if (+value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Should be positive'));
                },
              }]}
            >
              <LabelInput
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Trading amount
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                value={tradingAmount}
                placeholder='Specify amount to use'
                onChange={changeInputValue(setTradingAmount, 'tradingAmount')}
              />
            </Form.Item>

            <Form.Item
              name='maxActivePositions'
              style={formItem}
              rules={[{
                required: true,
                message: 'Max active positions is required',
              }, {
                validator: (_, value) => {
                  if (+value > 0 && Number.isInteger(+value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Should be positive integer'));
                },
              }]}
            >
              <LabelInput
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Max active positions
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                value={maxActivePositions}
                placeholder='Number of active positions'
                onChange={changeInputValue(setMaxActivePositions, 'maxActivePositions')}
              />
            </Form.Item>

            <SingleSelect
              label={(
                <Box sx={tooltipWrapper}>
                  <Text styles={tooltipTitle}>
                    Base order type
                  </Text>

                  <Tooltip title=''>
                    {SecondaryInfo}
                  </Tooltip>
                </Box>
              )}
              options={baseOrderTypes}
              select={{
                value: baseOrderType,
                onChange: changeSelectValue(setBaseOrderType, 'baseOrderType'),
                placeholder: '',
              }}
            />

            {!(baseOrderType === 'MARKET' || baseOrderType === 'LIMIT') && (
              <SingleSelect
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Conditional price type
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                options={conditionalPriceTypes}
                select={{
                  value: conditionalPriceType,
                  onChange: changeSelectValue(setConditionalPriceType, 'conditionalPriceType'),
                  placeholder: '',
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Form>
  );
};
