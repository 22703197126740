import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getExchanges, getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { exchangeIcons } from 'shared/consts/exchange-icons';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
  TwoFactorFailed as ErrorConnectModal,
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
} from 'shared/ui/modals';

interface ExchangeConfig {
  name: string;
  logo: React.ReactNode;
  oauthUrl: string;
  createAccountUrl: string;
  fields: Array<{
    label: string;
    key: string;
    mask?: boolean;
    placeholder: string;
  }>;
}

const exchangeConfigs: Record<string, ExchangeConfig> = {
  bybit: {
    name: 'Bybit',
    logo: exchangeIcons['bybit'],
    oauthUrl: 'https://www.bybit.com/en/oauth?client_id=c1d92cd6aab75&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fcreate%2Fbybit&response_type=code&scope=openapi&state=',
    createAccountUrl: 'https://partner.bybit.com/b/skyrexio',
    fields: [
      {
        label: 'Enter any name', key: 'exchangeTitle', placeholder: 'Name', 
      },
      {
        label: 'Paste your API key', key: 'apiKey', placeholder: 'Key', 
      },
      {
        label: 'Paste your API secret', key: 'apiSecret', mask: true, placeholder: 'Secret', 
      },
    ],
  },
  binance: {
    name: 'Binance',
    logo: exchangeIcons['binance'],
    oauthUrl: 'https://accounts.binance.com/oauth/authorize?client_id=9PUQLoXYrS&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fcreate%2Fbinance&response_type=code&scope=user%3AopenId%2Ccreate%3Aapikey%2Caccount%3Astatus&state=',
    createAccountUrl: 'https://accounts.binance.com/register?ref=QB4A8B0X',
    fields: [
      {
        label: 'Enter any name', key: 'exchangeTitle', placeholder: 'Name', 
      },
      {
        label: 'Paste your API key', key: 'apiKey', placeholder: 'Key', 
      },
      {
        label: 'Paste your API secret', key: 'apiSecret', mask: true, placeholder: 'Secret', 
      },
    ],
  },
  okx: {
    name: 'OKX',
    logo: exchangeIcons['okx'],
    oauthUrl: 'https://www.okx.com/account/oauth?access_type=offline&client_id=5456c77cf4d24df885ecc2ab88b90f268XsZQIsq&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fcreate%2Fokx&response_type=code&scope=fast_api&state=',
    createAccountUrl: 'https://www.okx.com/join/82800770',
    fields: [
      {
        label: 'Enter any name', key: 'exchangeTitle', placeholder: 'Name', 
      },
      {
        label: 'Paste your API key', key: 'apiKey', placeholder: 'Key', 
      },
      {
        label: 'Paste your API secret', key: 'apiSecret', mask: true, placeholder: 'Secret', 
      },
      {
        label: 'Passphrase', key: 'passphrase', placeholder: 'Input here', 
      },
    ],
  },
};

export const CreateExchange: React.FC<CreateExchangeProps & { exchangeType: keyof typeof exchangeConfigs; }> = ({
  isModalView,
  returnToAllExchanges,
  handleClose,
  exchangeType,
}) => {
  
  const uuid = getSkyrexUuid();
  const dispatch = useDispatch();
  const config = exchangeConfigs[exchangeType];
  const navigate = useNavigate();

  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [connectExchangeSuccessModalOpen, setConnectExchangeSuccessModalOpen] = useState<boolean>(false);
  const [connectExchangeErrorModalOpen, setExchangeConnectErrorModalOpen] = useState<boolean>(false);
  const [connectExchangeReachedErrorModalOpen, setExchangeConnectReachedErrorModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseConnectErrorModal = () => setExchangeConnectErrorModalOpen(false);

  const handleCloseModal = () => {
    setConnectExchangeSuccessModalOpen(false);
    handleClose?.();
  };

  const connectExchangeWithApiKeys = async () => {
    setIsLoading(true);
    try {
      const dataForConnectOauth = {
        uuid: uuid ?? '',
        exchangeName: exchangeType,
        ...formData,
      };

      const connectResponse = await connectExchange(dataForConnectOauth);

      if (!connectResponse.success) {
        if (connectResponse.data.message === 'Maximum number of active accounts reached. Please upgrade subscription') {
          setExchangeConnectReachedErrorModalOpen(true);
          return;
        }

        setExchangeConnectErrorModalOpen(true);
        return;
      }
      
      const exchangesIdsResponse = await getExchangesIds(uuid ?? '');
      if (!exchangesIdsResponse.success) {        
        setExchangeConnectErrorModalOpen(true);
        return;
      }
      
      const {
        accounts, 
      } = exchangesIdsResponse.data;
      const accountsIds = accounts.map(
        (account: ExchangeAccount) => account.exchangeAccountUuid,
      );

      const allAccountsData = await getExchanges(accountsIds);
      dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
      setIsLoading(false);
      setConnectExchangeSuccessModalOpen(true);
      return;
    } catch (error) {
      setExchangeConnectErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev, [key]: value, 
    }));
  };

  const connectExchangeOauth = () => {
    const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
    const url = `${config.oauthUrl}${randomHash}`;
    window.open(url, '_self');
  };

  const switchForm = () => {
    setIsApiKeysConnection(prev => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          returnToAllExchanges={returnToAllExchanges}
          type={exchangeType}
          fields={config.fields.map(field => ({
            ...field,
            value: formData[field.key] || '',
            onChange: (value: string) => handleInputChange(field.key, value),
          }))}
          actionBlock={(
            <ActionBlock
              button={{
                label: `Connect ${config.name}`,
                action: connectExchangeWithApiKeys,
              }}
              link={{
                label: (
                  <Box display='flex' alignItems='center' gap={1}>
                    Connect with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
              createAccount={{
                label: `Create ${config.name} account`,
                link: config.createAccountUrl,
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        returnToAllExchanges={returnToAllExchanges}
        steps={[
          `Click on Connect ${config.name} button`,
          `You will be redirected to ${config.name}`,
          'Confirm your connection to Skyrex',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: `Connect ${config.name}`,
              action: connectExchangeOauth,
            }}
            link={{
              label: 'Connect with API keys',
              action: switchForm,
            }}
            createAccount={{
              label: `Create ${config.name} account`,
              link: config.createAccountUrl,
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={connectExchangeSuccessModalOpen}
        onClose={handleCloseModal}
        title={'Exchange account connected successfully'}
        description={'Now you are in one click to start trading bot or create manual trade using your account'}
        action={handleCloseModal}
      />

      <ErrorConnectModal
        isOpen={connectExchangeErrorModalOpen}
        title='Failed to connect account'
        description='Check API keys settings are correct and try again'
        closeHandler={handleCloseConnectErrorModal}
      />

      <EmptySubscription
        isOpen={connectExchangeReachedErrorModalOpen}
        handleClose={() => {
          setExchangeConnectReachedErrorModalOpen(false);
        }}
        modalTitle='Upgrade plan'
        modalDescription='Maximum connected exchange accounts is reached'
        modalButtonTitle='Upgrade'
        modalButtonAction={() => {
          navigate('/subscriptions');
        }}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Connect</Title>
            <Box sx={platformLogo}>{config.logo}</Box>
            <Title>exchange</Title>
          </Box>
          {getContent()}
        </Box>
      </Box>
    </>
  );
};
