import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { getUserSkyrexUuid } from 'entities/user/model/selectors/get-user-skyrex-uuid/get-user-skyrex-uuid';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getExchanges,
  getExchangesIds,
} from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import {
  getWrapperStyles,
  logo,
  platformLogo,
  title,
} from 'widgets/exchanges/styles';
import { exchangeIcons } from 'shared/consts/exchange-icons';
import { Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
  TwoFactorFailed as ErrorConnectModal,
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
} from 'shared/ui/modals';

export const CreateGate = (props: CreateExchangeProps) => {
  const {
    isModalView,
    returnToAllExchanges,
    handleClose,
  } = props;
  const uuid = useSelector(getUserSkyrexUuid);

  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [exchangeTitle, setExchangeTitle] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectExchangeError, setExchangeConnectError] = useState<boolean>(false);
  const [connectExchangeReachedErrorModalOpen, setExchangeConnectReachedErrorModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseConnectErrorModal = () => setExchangeConnectError(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleClose?.();
  };

  const connectGateWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForConnectOauth = {
        uuid,
        exchangeTitle,
        exchangeName: 'gateio',
        apiKey,
        apiSecret,
      };

      const gateConnectResponse = await connectExchange(dataForConnectOauth);

      if (gateConnectResponse.success) {
        const exchangesIdsResponse = await getExchangesIds(uuid);
        if (exchangesIdsResponse.success) {
          const {
            accounts,
          } = exchangesIdsResponse.data;
          const accountsIds = accounts.map(
            (account: ExchangeAccount) => account.exchangeAccountUuid,
          );

          const allAccountsData = await getExchanges(accountsIds);
          dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
          setIsLoading(false);
          setIsModalOpen(true);
          
          return;
        }
      }

      if (gateConnectResponse.data.message === 'Maximum number of active accounts reached. Please upgrade subscription') {
        setExchangeConnectReachedErrorModalOpen(true);
        return;
      }

      setExchangeConnectError(true);
      setIsModalOpen(true);
    } catch (error) {
      setExchangeConnectError(true);
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };

  const switchForm = () => {
    setIsApiKeysConnection((prev) => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          returnToAllExchanges={returnToAllExchanges}
          type='binance'
          fields={[{
            label: 'Enter any name',
            value: exchangeTitle,
            placeholder: 'Name',
            onChange: handleChangeTitle,
          }, {
            label: 'Paste your API key',
            value: apiKey,
            placeholder: 'Key',
            onChange: handleChangeApiKey,
          }, {
            label: 'Paste your API secret',
            mask: true,
            value: apiSecret,
            placeholder: 'Secret',
            onChange: handleChangeApiSecret,
          }]}
          actionBlock={(
            <ActionBlock
              button={{
                label: 'Connect Gate.io',
                action: connectGateWithApiKeys,
              }}
              link={{
                label: (
                  <Box display='flex' alignItems='center' gap={1}>
                    Connect with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
              createAccount={{
                label: 'Create Gate.io account',
                link: 'https://www.gate.io/referral/invite/SKYREXIO_0_102',
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        returnToAllExchanges={returnToAllExchanges}
        steps={[
          'Click on Connect Gate.io button',
          'You will be redirected to Gate.io',
          'Confirm your connection to Skyrexio',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: 'Connect Gate.io',
              action: () => {},
              disabled: true,
            }}
            link={{
              label: 'Connect with API keys',
              action: switchForm,
            }}
            createAccount={{
              label: 'Create Gate.io account',
              link: 'https://www.gate.io/referral/invite/SKYREXIO_0_102',
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={'Exchange account connected successfully'}
        description={'Now you are in one click to start trading bot or create manual trade using your account'}
        action={handleCloseModal}
      />

      <ErrorConnectModal
        isOpen={connectExchangeError}
        title='Failed to connect account'
        description='Check API keys settings are correct and try again'
        closeHandler={handleCloseConnectErrorModal}
      />

      <EmptySubscription
        isOpen={connectExchangeReachedErrorModalOpen}
        handleClose={() => {
          setExchangeConnectReachedErrorModalOpen(false);
        }}
        modalTitle='Upgrade plan'
        modalDescription='Maximum connected exchange accounts is reached'
        modalButtonTitle='Upgrade'
        modalButtonAction={() => {
          navigate('/subscriptions');
        }}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title styles={title}>
              Connect
            </Title>

            <Box sx={platformLogo}>
              {exchangeIcons['gateio']}
            </Box>

            <Title styles={title}>
              exchange
            </Title>
          </Box>

          {getContent()}
        </Box>
      </Box>
    </>
  );
};
