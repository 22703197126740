import { Box } from '@mui/material';
import { PieChart } from 'widgets';
import { ConfirmTransaction, SingleSelect, Table, Text } from 'shared/ui';
import { Buy, History, Sell, Wrapper } from '../components';
import { filters, graphicWrapper, tableWrapper, wrapper } from '../styles';

export const TradingTerminal = () => {
  const getContent = (selectedSegment: string, isSkipBaseOrder: boolean) => {
    if (selectedSegment === 'buy') {
      return (
        <Buy isSkipBaseOrder={isSkipBaseOrder} />
      );
    }

    return (
      <Sell isSkipBaseOrder={isSkipBaseOrder} />
    );
  };

  return (
    <Box sx={wrapper}>
      <Box sx={tableWrapper}>
        <Box sx={filters}>
          <SingleSelect
            label='Exchange account'
            options={[]}
            select={{
              placeholder: 'Select exchange account',
              onChange: () => {},
            }}
          />

          <SingleSelect
            showSearch={true}
            label='Market'
            options={[{
              label: 'BTC',
              value: '0.02023760',
              key: 1,
            }, {
              label: 'USDC',
              value: '0',
              key: 2,
            }, {
              label: 'DAI',
              value: '0',
              key: 3,
            }, {
              label: 'EUR',
              value: '0',
              key: 4,
            }]}
            select={{
              placeholder: 'Select market',
              onChange: () => {},
            }}
            filterSort={(a, b) => String(a.label).toLowerCase().localeCompare(String(b.label).toLowerCase())}
            optionRender={(option) => (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Text type='success'>
                  {option.label}
                </Text>

                <Box
                  display='flex'
                  alignItems='center'
                  gap={0.5}
                >
                  <Text>
                    {option.value}
                  </Text>

                  <Text type='secondary'>
                    {option.label}
                  </Text>
                </Box>
              </Box>
            )}
            labelRender={(props) => {
              console.log('props', props);
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                >
                  <Text type='success'>USDT</Text>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text>7069.23</Text>
                    <Text type='secondary'>USDT</Text>
                  </Box>
                </Box>
              );
            }}
          />

          <SingleSelect
            showSearch={true}
            label='Trading Pair'
            options={[{
              label: 'MATIC — USDT',
              value: '4847529.0000',
            }]}
            select={{
              placeholder: 'Select trading pair',
              onChange: () => {},
            }}
            filterSort={(a, b) => String(a.label).toLowerCase().localeCompare(String(b.label).toLowerCase())}
            optionRender={(option) => (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Text type='success'>
                  {option.label}
                </Text>

                <Box
                  display='flex'
                  alignItems='center'
                  gap={0.5}
                >
                  <Text>
                    {option.value}
                  </Text>

                  <Text type='secondary'>
                    {option.label?.toString().split(' ')[0]}
                  </Text>
                </Box>
              </Box>
            )}
            labelRender={(props) => {
              console.log('props', props);
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                >
                  <Text type='success'>MATIC — USDT</Text>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text>4847529.0000</Text>
                    <Text type='secondary'>MATIC</Text>
                  </Box>
                </Box>
              );
            }}
          />
        </Box>

        <Table
          title=''
          columns={[]}
          items={[{
            symbol: 'BTC',
            share: '47.31%',
            change: '7.31%',
            price: '7466.50',
            amount: '196266.55588580',
            total: '7361.50',
          }, {
            symbol: 'BTC',
            share: '47.31%',
            change: '7.31%',
            price: '7466.50',
            amount: '196266.55588580',
            total: '7361.50',
          }, {
            symbol: 'BTC',
            share: '47.31%',
            change: '7.31%',
            price: '7466.50',
            amount: '196266.55588580',
            total: '7361.50',
          }, {
            symbol: 'BTC',
            share: '47.31%',
            change: '7.31%',
            price: '7466.50',
            amount: '196266.55588580',
            total: '7361.50',
          }, {
            symbol: 'BTC',
            share: '47.31%',
            change: '7.31%',
            price: '7466.50',
            amount: '196266.55588580',
            total: '7361.50',
          }, {
            symbol: 'BTC',
            share: '47.31%',
            change: '7.31%',
            price: '7466.50',
            amount: '196266.55588580',
            total: '7361.50',
          }]}
          itemsCount={5}
          graphic={(
            <Box
              display='block'
              width={245}
              minWidth={245}
            >
              <PieChart
                totalBtc={1}
                totalUsdt={2}
                usdtDailyChangePercent={'10'}
                btcDailyChangePercent={'20'}
                data={[]}
              />
            </Box>
          )}
        />
      </Box>

      <Box sx={graphicWrapper}>
        <div></div>

        <Wrapper>
          {(selectedSegment: string, isSkipBaseOrder: boolean) => getContent(selectedSegment, isSkipBaseOrder)}
        </Wrapper>
      </Box>

      <History />

      <ConfirmTransaction
        isOpened={false}
        closeHandler={() => {}}
        data={[{
          title: 'Base order',
          columns: [
            'Side',
            'Price',
            'Units',
            'Total',
            'Type',
          ],
          items: [[{
            value: 'Buy',
          }, {
            value: '0.027241 USDT',
          }, {
            value: '256.3 MATIC',
          }, {
            value: '0.24 BUSDT',
          }, {
            value: 'Cond. market',
          }], [{
            value: 'Buy',
          }, {
            value: '0.027241 USDT',
          }, {
            value: '256.3 MATIC',
          }, {
            value: '0.24 BUSDT',
          }, {
            value: 'Cond. market',
          }], [{
            value: 'Buy',
          }, {
            value: '0.027241 USDT',
          }, {
            value: '256.3 MATIC',
          }, {
            value: '0.24 BUSDT',
          }, {
            value: 'Cond. market',
          }]],
        }, {
          title: 'Additional base orders',
          columns: [
            'Side',
            'Price',
            'Units',
            'Total',
            'Type',
          ],
          items: [[{
            value: 'Buy',
          }, {
            value: '0.027241 USDT',
          }, {
            value: '256.3 MATIC',
          }, {
            value: '0.24 BUSDT',
          }, {
            value: 'Cond. market',
          }]],
        }, {
          title: 'Take profit targets',
          columns: [
            'Volume',
            'Price',
            'Percent',
            'Calculation',
            'Type',
          ],
          items: [[{
            value: '75%',
          }, {
            value: '0.027241 USDT',
          }, {
            value: '-10.00%',
            type: 'success',
          }, {
            value: 'Average',
          }, {
            value: 'Cond. market',
          }], [{
            value: '75%',
          }, {
            value: '0.027241 USDT',
          }, {
            value: '-10.00%',
            type: 'danger',
          }, {
            value: 'Average',
          }, {
            value: 'Cond. market',
          }]],
        }, {
          title: 'Stop loss',
          columns: [
            'Percent',
            'Price',
            'Calculation',
            'Type',
          ],
          items: [[{
            value: '+9.00%',
            type: 'danger',
          }, {
            value: '0.0034597 USDT',
          }, {
            value: 'Average',
          }, {
            value: 'Cond. market',
          }]],
        }]}
      />
    </Box>
  );
};
