import { Box } from '@mui/material';
import { getExchangeData } from 'entities/exchange/model/selectors/get-exchange-data/get-exchange-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount, ExchangeCode } from 'entities/exchange/model/types/exchange-account';
import { deleteExchange } from 'features/delete-exchange/api/delete-exchange';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlatformCard } from 'widgets';
import { EMPTY_ARRAY } from 'shared/consts/common-constants';
import { PlusGreen } from 'shared/icons';
import { MainButton, Title } from 'shared/ui';
import { ConnectNewExchange,
  ConnectedExchangeAccountStatus,
  ConnectedExchangeAccountStatus as SuccessModal,
  ConnectedExchangeAccountStatus as DeletedSuccesFullyModal, 
  TwoFactorFailed as ErrorOauthModal } from 'shared/ui/modals';
import { UpdateExchange } from 'shared/ui/modals/update-exchange';
import { connectButton, list, title, titleWrapper, wrapper } from '../styles';
import { UpdateApiKeysModalProps } from '../types/update-keys.types';

interface ConnectedExchangeAccountListPageProps {
  exchangeConnectionStatus?: boolean;
}

export const ConnectedExchangeAccountListPage = (props: ConnectedExchangeAccountListPageProps) => {
  const {
    exchangeConnectionStatus,
  } = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteModalSuccessOpen, setIsDeleteModalSuccess] = useState<boolean>(false);
  const [exchangeToUpdate, setExchangeToUpdate] = useState<string>('');
  const [updateApiKeysModalOpen, setUpdateApiKeysModalOpen] = useState<boolean>(false);
  const [exchangeToDelete, setExchangeToDelete] = useState<string | null>(null);
  const [exchangeUuidToUpdateApiKeys, setExchangeUuidToUpdateApiKeys] = useState<string>('');
  const [exhangeTitleToUpdateApiKeys, setExhangeTitleToUpdateApiKeys] = useState<string>('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [oauthConnectSucces, setOauthConnectSuccess] = useState<boolean | undefined>(false);
  const [oauthConnectError, setOauthConnectError] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getExchangeName: Record<ExchangeCode, string> = {
    binance: 'Binance',
    bybit: 'Bybit',
    okx: 'OKX',
    gateio: 'Gate.io',
    'crypto-com': 'Crypto.com',
  };

  useEffect(() => {
    if (exchangeConnectionStatus === true) {
      setOauthConnectSuccess(true);
    } else if (exchangeConnectionStatus === false) {
      setOauthConnectError(true);
    }
  }, [exchangeConnectionStatus]);

  const handleAddExchangeModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseError = () => {
    setIsErrorModalOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setOauthConnectSuccess(false);
  };

  const handleCloseErrorModal = () => {
    setOauthConnectError(false);
  };

  const handleOpenDeleteModal = (exchangeAccountUuid: string) => {
    setExchangeToDelete(exchangeAccountUuid);
    setDeleteModalOpen(true);
  };

  const handleOpenUpdateApiKeysModal = (props: UpdateApiKeysModalProps) => {
    const {
      exchangeAccountUuid, exchangeCode, exchangeTitle, 
    } = props;
    setExchangeUuidToUpdateApiKeys(exchangeAccountUuid);
    setExhangeTitleToUpdateApiKeys(exchangeTitle);
    setExchangeToUpdate(exchangeCode); 
    setUpdateApiKeysModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setExchangeToDelete(null);
  };

  const handleCloseExchangeDeletedSuccesfullyModal = () => {
    setIsDeleteModalSuccess(false);
  };

  const handleCloseUpdateApiKeysModal = () => {
    setUpdateApiKeysModalOpen(false);
  };

  const handleExchangeDeletedSuccessfully = () => {
    setIsDeleteModalSuccess(true);

    const timer = setTimeout(() => {
      setIsDeleteModalSuccess(false);
    }, 3000);

    return () => clearTimeout(timer);
  };

  const handleDeleteExchange = async () => {
    if (exchangeToDelete) {
      try {
        const deleteExchangeResponse = await deleteExchange(exchangeToDelete);
        if (deleteExchangeResponse.success) {
          dispatch(exchangeActions.deleteExchange(exchangeToDelete));
          handleCloseDeleteModal();
          handleExchangeDeletedSuccessfully();
          return;
        }

        setDeleteModalOpen(false);
        setIsErrorModalOpen(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleOpenTerminalPage = () => {
    navigate('/manual-trading/trading-terminal');
  };

  const handleOpenExchangePage = (id: string) => {
    navigate(`/my-accounts/${id}`);
  };

  const connectedExchanges = useSelector(getExchangeData);

  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={title}>My exchange accounts</Title>

        <ConnectNewExchange 
          isModalOpen={isModalOpen} 
          setIsModalOpen={setIsModalOpen} 
        />

        <MainButton 
          type='primary' 
          ghost 
          styles={connectButton} 
          onClick={handleAddExchangeModal}
        >
          <Box 
            display='flex' 
            alignItems='center' 
            gap={1}
          >
            {PlusGreen}
            Connect new exchange
          </Box>
        </MainButton>
      </Box>

      <UpdateExchange
        isModalView={false}
        platform={exchangeToUpdate}
        isUpdateModalOpen={updateApiKeysModalOpen}
        closeExchangeModal={handleCloseUpdateApiKeysModal}
        exchangeAccountUuid={exchangeUuidToUpdateApiKeys}
        currentExchangeTitle={exhangeTitleToUpdateApiKeys}
      />

      <ConnectedExchangeAccountStatus
        buttonTitle='Delete exchange'
        buttonStyles={{
          border: '1px solid red',
          color: 'red',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        isOpen={deleteModalOpen}
        isError
        title='Delete account?'
        onClose={handleCloseDeleteModal}
        description='All trading history, statistics, SmartTrades and Bots will be deleted for this exchange account'
        action={handleDeleteExchange}
      />
      <ConnectedExchangeAccountStatus
        buttonTitle='Okay'
        buttonStyles={{
          color: 'grey',
          border: '1px solid #E9E9E9',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        isOpen={isErrorModalOpen}
        isError
        onClose={handleCloseError}
        title='Failed to delete account'
        description='Close active trades, delete bots and try again'
        action={handleCloseError}
      />

      <SuccessModal
        buttonTitle='Okay'
        buttonStyles={{
          color: '#2ECD99',
          border: '0.5px solid #2ECD99',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        isOpen={oauthConnectSucces}
        action={handleCloseSuccessModal}
        onClose={handleCloseSuccessModal}
        title='Account is connected'
        description='Pro level trading is just one click away'
      />

      <ErrorOauthModal
        isOpen={oauthConnectError}
        title='Failed to connect account'
        description='Account should have balance and no other active Fast API'
        closeHandler={handleCloseErrorModal}
      />

      <DeletedSuccesFullyModal
        isOpen={deleteModalSuccessOpen}
        onClose={handleCloseExchangeDeletedSuccesfullyModal}
        action={handleCloseExchangeDeletedSuccesfullyModal}
        title='Account is deleted'
        description={'No worries, it\'s just a link to account. Create a new one!'}
      />

      <Box sx={list}>
        {connectedExchanges.map((exchange: ExchangeAccount) => (
          <PlatformCard
            key={exchange.exchangeAccountUuid}
            exchangeCode={exchange.exchangeCode}
            title={exchange.accountName}
            usdtDailyChangePercent={exchange.usdtDailyChangePercent}
            usdtDailyChangeValue={exchange.usdtDailyChangeValue}
            btcDailyChangePercent={exchange.btcDailyChangePercent}
            btcDailyChangeValue={exchange.btcDailyChangeValue}
            description={`${getExchangeName[exchange.exchangeCode]} Spot`}
            totalUsdt={exchange.totalUsdt}
            balance={{
              index: 0,
              max: 100,
            }}
            exchangeAccountUuid={exchange.exchangeAccountUuid}
            total={{
              usd: exchange.totalUsdt ?? 'no value',
              btc: exchange.totalBtc ?? 'no value',
            }}
            balances={exchange.balances ?? EMPTY_ARRAY}
            trade={handleOpenTerminalPage}
            view={handleOpenExchangePage}
            onDelete={handleOpenDeleteModal}
            onUpdateApiKeys={handleOpenUpdateApiKeysModal}
          />
        ))}
      </Box>
    </Box>
  );
};
