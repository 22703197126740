import { Box } from '@mui/material';
import { Colors } from 'shared/consts/colors';
import { CryptoCardIcon } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { IBot } from '../interfaces';
import { inner, planName, price, title, titleWrapper, wrapper } from '../styles';

export const Bot = (props: IBot) => {
  const {
    subscription,
  } = props;

  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={planName}>
          {subscription.name}
        </Title>

        <Text styles={price}>
          ${subscription.fee}
        </Text>
      </Box>

      <Box sx={inner}>
        <Text styles={title}>
          Payment method
        </Text>

        <Box
          sx={{
            '&>svg': {
              width: '100%',
              '&>rect:last-of-type': {
                stroke: Colors.GREEN,
                strokeOpacity: '1',
              },
            },
          }}
        >
          {CryptoCardIcon}
        </Box>
      </Box>
    </Box>
  );
};
