// @ts-nocheck

import { getPrecisionNumber, sanitizeSymbol, sanitizeSymbolCryptoCom, sanitizeSymbolGate } from './common-utils';

function getPrecisionString(value: string | number): string {
  const precision = value;
  return (1 / Math.pow(10, precision)).toFixed(precision);
}

export function adaptBinanceSymbol(symbol) {
  
  const priceFilter = symbol.filters.find(
    (el) => el.filterType === 'PRICE_FILTER',
  );
  const lotFilter = symbol.filters.find((el) => el.filterType === 'LOT_SIZE');
  const notionalFilter = symbol.filters.find(
    (el) => el.filterType === 'NOTIONAL',
  );
  
  const marketLotFilter = symbol.filters?.find(
    (el) => el.filterType === 'MARKET_LOT_SIZE',
  );  
  
  return {
    symbol: symbol.symbol,
    baseAsset: symbol.baseAsset,
    baseAssetPrecision: getPrecisionNumber(lotFilter.stepSize),
    quoteAsset: symbol.quoteAsset,
    quoteAssetPrecision: getPrecisionNumber(priceFilter.tickSize),
    // quoteAssetPrecision: symbol.quoteAssetPrecision,
    priceTickSize: priceFilter ? priceFilter.tickSize : undefined,
    priceMin: priceFilter ? priceFilter.minPrice : undefined,
    priceMax: priceFilter ? priceFilter.maxPrice : undefined,
    minNotional: notionalFilter ? notionalFilter.minNotional : undefined,
    maxNotional: notionalFilter ? notionalFilter.maxNotional : undefined,
    lotMax: lotFilter ? lotFilter.maxQty : undefined,
    lotMin: lotFilter ? lotFilter.minQty : undefined,
    lotTickSize: lotFilter ? lotFilter.stepSize : undefined,
    marketLotMax: marketLotFilter ? marketLotFilter.maxQty : 99999999,
    marketLotMin: marketLotFilter ? marketLotFilter.minQty : 99999999,
    chartPrecision: getPrecisionNumber(priceFilter.tickSize), //2
    baseCurrencyChartPrecision: getPrecisionNumber(lotFilter.stepSize), //5
  };
}
  
export function adaptBybitSymbol(symbol) {
  // Destructure the necessary fields from the Bybit response
  const {
    symbol: symbolName,
    baseCoin,
    quoteCoin,
    lotSizeFilter,
    priceFilter,
  } = symbol;
  
  // Extract values from the lotSizeFilter and priceFilter objects
  const {
    basePrecision,
    quotePrecision,
    minOrderQty,
    maxOrderQty,
    minOrderAmt,
    maxOrderAmt,
  } = lotSizeFilter;
  
  const {
    tickSize, 
  } = priceFilter;
  
  // Adapt the Bybit response to the common data model
  return {
    symbol: symbolName,
    baseAsset: baseCoin,
    baseAssetPrecision: getPrecisionNumber(basePrecision),
    quoteAsset: quoteCoin,
    quoteAssetPrecision: getPrecisionNumber(quotePrecision),
    priceTickSize: tickSize,
    priceMin: undefined,
    priceMax: undefined,
    minNotional: minOrderAmt,
    maxNotional: maxOrderAmt,
    lotMax: maxOrderQty,
    lotMin: minOrderQty,
    lotTickSize: basePrecision,
    chartPrecision: getPrecisionNumber(tickSize),
    baseCurrencyChartPrecision: getPrecisionNumber(basePrecision),
  };
}
  
export function adaptOkxSymbol(symbol) {
  // Extract the necessary fields from the OKX response
  const {
    instId,
    baseCcy,
    quoteCcy,
    lotSz,
    tickSz,
    minSz,
    maxLmtSz, // Assuming this is equivalent to maxOrderQty in your model
  } = symbol;
    
  // Adapt the OKX response to the common data model
  return {
    symbol: sanitizeSymbol(instId),
    baseAsset: baseCcy,
    chartPrecision: getPrecisionNumber(tickSz),
    baseAssetPrecision: getPrecisionNumber(lotSz), // OKX response does not provide a direct equivalent
    quoteAsset: quoteCcy,
    quoteAssetPrecision: getPrecisionNumber(tickSz), // OKX response does not provide a direct equivalent
    priceTickSize: tickSz,
    priceMin: undefined, // These values are not directly available in the OKX response
    priceMax: undefined, // These values are not directly available in the OKX response
    minNotional: undefined,
    maxNotional: undefined,
    lotMax: maxLmtSz,
    lotMin: minSz,
    lotTickSize: lotSz,
    okxInstId: instId,
  };
}

export function adaptGateIOSymbol(symbol) {
  const {
    id,
    quote,
    base,
    min_quote_amount,
    max_quote_amount,
    min_base_amount,
    amount_precision,
    precision,
  } = symbol;
  
  return {
    symbol: sanitizeSymbolGate(id),
    gateSymbol: id,
    
    baseAsset: base,
    baseAssetPrecision: amount_precision,
    quoteAsset: quote,
    quoteAssetPrecision: precision,
    
    chartPrecision: precision,
    baseCurrencyChartPrecision: amount_precision,
    
    minNotional: min_quote_amount,
    maxNotional: max_quote_amount,
    
    lotMin: min_base_amount,
    lotMax: 99999999,
  
    priceTickSize: getPrecisionString(precision),
    lotTickSize: getPrecisionString(amount_precision),
    
    priceMin: 0,
    priceMax: 999999999,
  };
}

interface CryptoComSymbol {
  symbol: string;
  quote_ccy: string;
  quote_decimals: number;
  price_tick_size: string;
  qty_tick_size: string;
  quantity_decimals: number;
  base_ccy: string;
}

interface AdaptedCryptoComSymbol {
  symbol: string;
  cryptoComSymbol: string;
  quoteAsset: string;
  quoteAssetPrecision: number;
  chartPrecision: number;
  baseAsset: string;
  baseAssetPrecision: number;
  minNotional: number;
  priceTickSize: string;
  lotTickSize: string;
  priceMin: null;
  priceMax: null;
  maxNotional: null;
  lotMax: null;
  lotMin: null;
}

export function adaptCryptoComSymbol(symbol: CryptoComSymbol): AdaptedCryptoComSymbol {
  const {
    symbol: id,
    quote_ccy,
    quote_decimals,
    price_tick_size,
    qty_tick_size,
    quantity_decimals,
    base_ccy,
  } = symbol;
  
  const minNotional = parseFloat(price_tick_size) * parseFloat(qty_tick_size);
  
  return {
    symbol: sanitizeSymbolCryptoCom(id),
    cryptoComSymbol: id,
    quoteAsset: quote_ccy,
    quoteAssetPrecision: quote_decimals,
    chartPrecision: quote_decimals,
    baseAsset: base_ccy,
    baseAssetPrecision: quantity_decimals,
    minNotional: minNotional,
    priceTickSize: price_tick_size,
    lotTickSize: getPrecisionString(quantity_decimals),
    priceMin: 0,
    priceMax: 999999999,
    maxNotional: 999999999,
    lotMax: null,
    lotMin: null,
  };
}
