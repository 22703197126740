import { Box } from '@mui/material';
import { Form, FormInstance, Segmented } from 'antd';
import { baseOrderTypes, conditionalPriceTypes } from 'pages/trading-bots/configurator/consts';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { formItem } from 'pages/trading-bots/configurator/styles';
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from 'react';
import { formatFloat, formatPositiveInteger } from 'shared/helpers';
import { SecondaryInfo } from 'shared/icons';
import { LabelInput, SingleSelect, Switch, Text, Tooltip } from 'shared/ui';
import { blockWrapper, inputs, switchText, title, tooltipTitle, tooltipWrapper, topWrapper, wrapper } from '../styles';

const takeFilteredOrderTypes = baseOrderTypes.filter((item) => item.value !== 'MARKET' && item.value !== 'CONDITIONAL_LIMIT');
const stopFilteredOrderTypes = baseOrderTypes.filter((item) => item.value.includes('CONDITIONAL'));
const alertFilteredOrderTypes = baseOrderTypes.filter((item) => !item.value.includes('CONDITIONAL'));

export const Position = (props: any) => {
  const {
    setForm,
  } = props;

  const {
    initialDataValue: {
      closeOrders,
    },
    setInitialDataValue,
  } = useContext(MainContext);

  const [selectedSegment, setSelectedSegment] = useState<string>('preset');
  const [takeProfitOpened, setTakeProfitOpened] = useState<boolean>(true);
  const [stopLossOpened, setStopLossOpened] = useState<boolean>(true);
  const [orders, setOrders] = useState<string>('1');
  const [orderSize, setOrderSize] = useState<string>('1');
  const [orderPrice, setOrderPrice] = useState<string>('1');
  const [takePriceChange, setTakePriceChange] = useState<string>('5');
  const [stopPriceChange, setStopPriceChange] = useState<string>('-5');
  const [takeOrderType, setTakeOrderType] = useState<string>(takeFilteredOrderTypes[0].value);
  const [stopOrderType, setStopOrderType] = useState<string>(stopFilteredOrderTypes[0].value);
  const [alertOrderType, setAlertOrderType] = useState<string>(alertFilteredOrderTypes[0].value);
  const [takePriceType, setTakePriceType] = useState<string>(conditionalPriceTypes[0].value);
  const [stopPriceType, setStopPriceType] = useState<string>(conditionalPriceTypes[0].value);

  const [takeProfitForm] = Form.useForm();
  const [stopLossForm] = Form.useForm();

  const changeInputValue = (action: Dispatch<SetStateAction<string>>, field: string, form: FormInstance) => {
    return (value: string) => {
      const [type, key] = field.split('.');

      const replacedValue = key === 'orders' ? formatPositiveInteger(value) : formatFloat(value);

      action(replacedValue);
      form.setFieldValue(field, replacedValue);
      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          closeOrders: {
            ...prev.closeOrders,
            [type]: {
              ...prev.closeOrders[type],
              [key]: replacedValue,
            },
          },
        };
      });
    };
  };

  const changeSelectValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      action(value);

      const [type, key] = field.split('.');
      let priceType = '';
      if (type === 'takeProfit' && key === 'orderType' && value === 'CONDITIONAL_MARKET') {
        priceType = takePriceType;
      }

      if (type === 'stopLoss' && key === 'orderType') {
        priceType = stopPriceType;
      }

      if ((type === 'takeProfit' || type === 'stopLoss') && key === 'conditionalPriceType') {
        priceType = value;
      }

      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          closeOrders: {
            ...prev.closeOrders,
            [type]: {
              ...prev.closeOrders[type],
              [key]: value,
              conditionalPriceType: priceType,
            },
          },
        };
      });
    };
  };

  const openBlockHandler = (action: Dispatch<SetStateAction<boolean>>, type: string) => {
    return (value: boolean) => {
      action(value);

      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          closeOrders: {
            ...prev.closeOrders,
            [type]: {
              ...prev.closeOrders[type],
              isOpened: value,
            },
          },
        };
      });
    };
  };

  const changeSegment = (value: string) => {
    setSelectedSegment(value);

    let alertType = '';
    if (value === 'alert') {
      alertType = alertOrderType;
    }

    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        closeOrders: {
          ...prev.closeOrders,
          type: value,
          orderType: alertType,
          alert: {
            alertOrderType: alertType,
          },
        },
      };
    });
  };

  useEffect(() => {
    const orders = closeOrders.takeProfit.orders || '1';
    const orderSize = closeOrders.takeProfit.orderSizeScale || '1';
    const orderPrice = closeOrders.takeProfit.orderPriceChangeScale || '1';
    const takePriceChange = closeOrders.takeProfit.priceChange || '5';
    const takeOrderType = closeOrders.takeProfit.orderType || takeFilteredOrderTypes[0].value;
    const takePriceType = closeOrders.takeProfit.conditionalPriceType || conditionalPriceTypes[0].value;

    setOrders(orders);
    setOrderSize(orderSize);
    setOrderPrice(orderPrice);
    setTakePriceChange(takePriceChange);
    setTakeOrderType(takeOrderType);
    setTakePriceType(takePriceType);

    setStopPriceChange(closeOrders.stopLoss.priceChange || '-5');
    setStopOrderType(closeOrders.stopLoss.orderType || stopFilteredOrderTypes[0].value);
    setStopPriceType(closeOrders.stopLoss.conditionalPriceType || conditionalPriceTypes[0].value);

    setTakeProfitOpened(closeOrders.takeProfit.isOpened ?? true);
    setStopLossOpened(closeOrders.stopLoss.isOpened ?? true);
    setSelectedSegment(closeOrders.type || 'preset');
    setAlertOrderType(closeOrders.alert?.alertOrderType || closeOrders.orderType || alertFilteredOrderTypes[0].value);

    takeProfitForm.setFieldsValue({
      'takeProfit.orders': orders,
      'takeProfit.priceChange': takePriceChange,
      'takeProfit.orderSizeScale': orderSize,
      'takeProfit.orderPriceChangeScale': orderPrice,
    });

    stopLossForm.setFieldsValue({
      'stopLoss.priceChange': closeOrders.stopLoss.priceChange || '-5',
    });
  }, [closeOrders]);

  const getContent = () => {
    if (selectedSegment === 'preset') {
      return (
        <Fragment>
          <Form
            form={takeProfitForm}
            layout='vertical'
            initialValues={{
              'takeProfit.orders': orders,
              'takeProfit.priceChange': takePriceChange,
              'takeProfit.orderSizeScale': orderSize,
              'takeProfit.orderPriceChangeScale': orderPrice,
            }}
          >
            <Box sx={blockWrapper}>
              <Switch
                value={takeProfitOpened}
                size='small'
                suffixText={(
                  <Text styles={switchText}>
                    Take profit
                  </Text>
                )}
                onChange={openBlockHandler(setTakeProfitOpened, 'takeProfit')}
              />

              {takeProfitOpened && (
                <Box sx={inputs}>
                  <Form.Item
                    name='takeProfit.orders'
                    style={formItem}
                    rules={[{
                      required: true,
                      message: 'Orders is required',
                    }, {
                      validator(_, value) {
                        if (!Number.isInteger(+value) || +value === 0) {
                          return Promise.reject('Orders should be positive integer');
                        }

                        if (+value > 5) {
                          return Promise.reject('Orders should be less than 5');
                        }

                        return Promise.resolve();
                      },
                    }]}
                  >
                    <LabelInput
                      label={(
                        <Box sx={tooltipWrapper}>
                          <Text styles={tooltipTitle}>
                            Orders
                          </Text>

                          <Tooltip title=''>
                            {SecondaryInfo}
                          </Tooltip>
                        </Box>
                      )}
                      value={orders}
                      placeholder=''
                      onChange={changeInputValue(setOrders, 'takeProfit.orders', takeProfitForm)}
                    />
                  </Form.Item>

                  <Form.Item
                    name='takeProfit.priceChange'
                    style={formItem}
                    rules={[{
                      required: true,
                      message: 'Price change is required',
                    }, {
                      validator(_, value) {
                        if (+value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject('Price change should be positive');
                      },
                    }]}
                  >
                    <LabelInput
                      label={(
                        <Box sx={tooltipWrapper}>
                          <Text styles={tooltipTitle}>
                            Price change, %
                          </Text>

                          <Tooltip title=''>
                            {SecondaryInfo}
                          </Tooltip>
                        </Box>
                      )}
                      value={takePriceChange}
                      placeholder=''
                      onChange={changeInputValue(setTakePriceChange, 'takeProfit.priceChange', takeProfitForm)}
                    />
                  </Form.Item>

                  <SingleSelect
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Order type
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    options={takeFilteredOrderTypes}
                    select={{
                      value: takeOrderType,
                      onChange: changeSelectValue(setTakeOrderType, 'takeProfit.orderType'),
                      placeholder: '',
                    }}
                  />

                  {+orders > 1 && (
                    <Fragment>
                      <Form.Item
                        name='takeProfit.orderSizeScale'
                        style={formItem}
                        rules={[{
                          required: true,
                          message: 'Order size scale is required',
                        }, {
                          validator(_, value) {
                            if (+value > 0) {
                              return Promise.resolve();
                            }

                            return Promise.reject('Order size scale should be positive');
                          },
                        }]}
                      >
                        <LabelInput
                          label={(
                            <Box sx={tooltipWrapper}>
                              <Text styles={tooltipTitle}>
                                Order size scale
                              </Text>

                              <Tooltip title=''>
                                {SecondaryInfo}
                              </Tooltip>
                            </Box>
                          )}
                          value={orderSize}
                          placeholder=''
                          onChange={changeInputValue(setOrderSize, 'takeProfit.orderSizeScale', takeProfitForm)}
                        />
                      </Form.Item>

                      <Form.Item
                        name='takeProfit.orderPriceChangeScale'
                        style={formItem}
                        rules={[{
                          required: true,
                          message: 'Order price change scale is required',
                        }, {
                          validator(_, value) {
                            if (+value > 0) {
                              return Promise.resolve();
                            }

                            return Promise.reject('Order price change scale should be positive');
                          },
                        }]}
                      >
                        <LabelInput
                          label={(
                            <Box sx={tooltipWrapper}>
                              <Text styles={tooltipTitle}>
                                Order price change scale
                              </Text>

                              <Tooltip title=''>
                                {SecondaryInfo}
                              </Tooltip>
                            </Box>
                          )}
                          value={orderPrice}
                          placeholder=''
                          onChange={changeInputValue(setOrderPrice, 'takeProfit.orderPriceChangeScale', takeProfitForm)}
                        />
                      </Form.Item>
                    </Fragment>
                  )}


                  {takeOrderType !== 'LIMIT' && (
                    <SingleSelect
                      label={(
                        <Box sx={tooltipWrapper}>
                          <Text styles={tooltipTitle}>
                            Conditional price type
                          </Text>

                          <Tooltip title=''>
                            {SecondaryInfo}
                          </Tooltip>
                        </Box>
                      )}
                      options={conditionalPriceTypes}
                      select={{
                        value: takePriceType,
                        onChange: changeSelectValue(setTakePriceType, 'takeProfit.conditionalPriceType'),
                        placeholder: '',
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Form>

          <Form
            form={stopLossForm}
            layout='vertical'
            initialValues={{
              'stopLoss.priceChange': stopPriceChange,
            }}
          >
            <Box sx={blockWrapper}>
              <Switch
                value={stopLossOpened}
                size='small'
                suffixText={(
                  <Text styles={switchText}>
                    Stop loss
                  </Text>
                )}
                onChange={openBlockHandler(setStopLossOpened, 'stopLoss')}
              />

              {stopLossOpened && (
                <Box sx={inputs}>
                  <Form.Item
                    name='stopLoss.priceChange'
                    style={formItem}
                    rules={[{
                      required: true,
                      message: 'Price change is required',
                    }, {
                      validator(_, value) {
                        if (+value < 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject('Price change should be negative');
                      },
                    }]}
                  >
                    <LabelInput
                      label={(
                        <Box sx={tooltipWrapper}>
                          <Text styles={tooltipTitle}>
                            Price change, %
                          </Text>

                          <Tooltip title=''>
                            {SecondaryInfo}
                          </Tooltip>
                        </Box>
                      )}
                      value={stopPriceChange}
                      placeholder=''
                      onChange={changeInputValue(setStopPriceChange, 'stopLoss.priceChange', stopLossForm)}
                    />
                  </Form.Item>

                  <SingleSelect
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Order type
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    options={stopFilteredOrderTypes}
                    select={{
                      value: stopOrderType,
                      onChange: changeSelectValue(setStopOrderType, 'stopLoss.orderType'),
                      placeholder: '',
                    }}
                  />

                  <SingleSelect
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Conditional price type
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    options={conditionalPriceTypes}
                    select={{
                      value: stopPriceType,
                      onChange: changeSelectValue(setStopPriceType, 'stopLoss.conditionalPriceType'),
                      placeholder: '',
                    }}
                  />
                </Box>
              )}
            </Box>
          </Form>
        </Fragment>
      );
    }

    return (
      <Box sx={inputs}>
        <SingleSelect
          label={(
            <Box sx={tooltipWrapper}>
              <Text styles={tooltipTitle}>
                Order type
              </Text>

              <Tooltip title=''>
                {SecondaryInfo}
              </Tooltip>
            </Box>
          )}
          options={alertFilteredOrderTypes}
          select={{
            value: alertOrderType,
            onChange: changeSelectValue(setAlertOrderType, 'alert.alertOrderType'),
            placeholder: '',
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        closeOrders: {
          ...prev.closeOrders,
          takeProfit: {
            ...prev.closeOrders.takeProfit,
            orderType: takeOrderType,
          },
          stopLoss: {
            ...prev.closeOrders.stopLoss,
            orderType: stopOrderType,
            conditionalPriceType: stopPriceType,
          },
        },
      };
    });
  }, []);

  useEffect(() => {
    const takeProfitFormData: any = {
      isOpened: takeProfitOpened,
      form: takeProfitForm,
      type: 'takeProfit',
    };
    setForm(takeProfitFormData);
  }, [takeProfitOpened]);

  useEffect(() => {
    const stopLossFormData: any = {
      isOpened: stopLossOpened,
      form: stopLossForm,
      type: 'stopLoss',
    };
    setForm(stopLossFormData);
  }, [stopLossOpened]);

  return (
    <Box sx={wrapper}>
      <Box sx={topWrapper}>
        <Text styles={title}>
          Close orders
        </Text>

        <Segmented
          value={selectedSegment}
          onChange={changeSegment}
          options={[{
            label: 'Preset',
            value: 'preset',
          }, {
            label: 'Alert',
            value: 'alert',
          }]}
        />
      </Box>

      {getContent()}
    </Box>
  );
};
