import { Box } from '@mui/material';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { updateExchange } from 'features/update-exchange/api/update-exchange';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { 
  getSkyrexUuid, 
  setExchangeAccountNameBeingUpdated, 
  setExchangeUuidBeingUpdated, 
} from 'shared/helpers/storage-helper';
import { BinanceLogo, BybitLogo, OkxLogo, Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
} from 'shared/ui/modals';

interface ExchangeConfig {
  name: string;
  logo: React.ReactNode;
  oauthUrl: string;
  fields: Array<{
    label: string;
    key: string;
    mask?: boolean;
    placeholder: string;
    type?: string;
  }>;
}

const exchangeConfigs: Record<string, ExchangeConfig> = {
  bybit: {
    name: 'Bybit',
    logo: BybitLogo,
    oauthUrl: 'https://www.bybit.com/en/oauth?client_id=c1d92cd6aab75&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fupdate%2Fbybit&response_type=code&scope=openapi&state=',
    fields: [
      {
        label: 'Enter any name', key: 'exchangeTitle', placeholder: 'Name', 
      },
      {
        label: 'Paste your API key', key: 'apiKey', placeholder: 'Key', 
      },
      {
        label: 'Paste your API secret', key: 'apiSecret', mask: true, placeholder: 'Secret', 
      },
    ],
  },
  binance: {
    name: 'Binance',
    logo: BinanceLogo,
    oauthUrl: 'https://accounts.binance.com/oauth/authorize?client_id=9PUQLoXYrS&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fupdate%2Fbinance&response_type=code&scope=user%3AopenId%2Ccreate%3Aapikey%2Caccount%3Astatus&state=',
    fields: [
      {
        label: 'Enter any name', key: 'exchangeTitle', placeholder: 'Name', 
      },
      {
        label: 'Paste your API key', key: 'apiKey', placeholder: 'Key', 
      },
      {
        label: 'Paste your API secret', key: 'apiSecret', mask: true, placeholder: 'Secret', 
      },
    ],
  },
  okx: {
    name: 'OKX',
    logo: OkxLogo,
    oauthUrl: 'https://www.okx.com/account/oauth?access_type=offline&client_id=5456c77cf4d24df885ecc2ab88b90f268XsZQIsq&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fupdate%2Fokx&response_type=code&scope=fast_api&state=',
    fields: [
      {
        label: 'Enter any name', key: 'exchangeTitle', placeholder: 'Name', 
      },
      {
        label: 'Paste your API key', key: 'apiKey', placeholder: 'Key', 
      },
      {
        label: 'Paste your API secret', key: 'apiSecret', mask: true, placeholder: 'Secret', 
      },
      {
        label: 'Passphrase', key: 'passphrase', type: 'password', placeholder: 'Input here', 
      },
    ],
  },
};

export const UpdateExchange: React.FC<UpdateExchangeProps & { exchangeType: keyof typeof exchangeConfigs; }> = ({
  isModalView,
  exchangeAccountUuid,
  currentExchangeTitle,
  closeExchangeModal,
  exchangeType,
}) => {
  const skyrexUserUuid = getSkyrexUuid();
  const dispatch = useDispatch();
  const config = exchangeConfigs[exchangeType];

  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, string>>({
    exchangeTitle: currentExchangeTitle,
  });
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onCloseErrorModal = () => {
    setIsModalOpen(false);
  };

  const onCloseSuccessModal = () => {
    setIsModalOpen(false);
    closeExchangeModal?.();
  };

  const updateExchangeWithOAuth = () => {
    const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
    const url = `${config.oauthUrl}${randomHash}`;
    setExchangeAccountNameBeingUpdated(currentExchangeTitle);
    setExchangeUuidBeingUpdated(exchangeAccountUuid);
    window.open(url, '_self');
  };

  const updateExchangeWithApiKeys = async () => {
    setIsLoading(true);
    setError(false);

    try {
      const dataForUpdateOauth = {
        exchangeAccountUuid,
        accountName: currentExchangeTitle,
        exchangeCode: exchangeType,
        ...formData,
      };

      const updateResponse = await updateExchange(dataForUpdateOauth);

      if (!updateResponse.success) {
        setError(true);
        return;
      }
      
      const exchangeAccounts = await setExchangeData(skyrexUserUuid ?? '');
      dispatch(exchangeActions.setAllExchanges(exchangeAccounts));
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
      setIsModalOpen(true);
    }
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev, [key]: value, 
    }));
  };

  const switchForm = () => {
    setIsApiKeysConnection(prev => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          type={exchangeType}
          fields={config.fields.map(field => ({
            ...field,
            disabled: field.key === 'exchangeTitle',
            value: formData[field.key] || '',
            onChange: (value: string) => handleInputChange(field.key, value),
          }))}
          actionBlock={(
            <ActionBlock
              button={{
                label: `Update ${config.name}`,
                action: updateExchangeWithApiKeys,
              }}
              link={{
                label: (
                  <Box display='flex' alignItems='center' gap={1}>
                    Update with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        steps={[
          `Click on Update ${config.name} button`,
          `You will be redirected to ${config.name}`,
          'Confirm your connection to Skyrex',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: `Update ${config.name}`,
              action: updateExchangeWithOAuth,
            }}
            link={{
              label: 'Update with API keys',
              action: switchForm,
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={error ? onCloseErrorModal : onCloseSuccessModal}
        isError={error}
        buttonStyles={error ? {
          border: '1px solid red',
          color: 'red',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        } : undefined}
        title={error ? 'Failed to update' : 'Exchange account updated successfully'}
        description={error
          ? 'Check permissions in API settings and try again'
          : 'Now you are in one click to start trading bot or create manual trade using your account'
        }
        action={error ? onCloseErrorModal : onCloseSuccessModal}
      />

      <Box position='relative'>
        {isLoading && <Loader isContentOverflow={true} />}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Update</Title>
            <Box sx={platformLogo}>{config.logo}</Box>
            <Title>exchange</Title>
          </Box>

          {getContent()}
        </Box>
      </Box>
    </>
  );
};
