import { BinanceIcon, BitmartIcon, ByBitIcon, CryptoComIcon, GateIoIcon, HtxIcon, KucoinIcon, OkxIcon } from 'shared/icons';

export interface IExchangeIcons {
  binance: JSX.Element;
  okx: JSX.Element;
  bybit: JSX.Element;
  'crypto-com': JSX.Element;
  gateio: JSX.Element;
  bitmart: JSX.Element;
  kucoin: JSX.Element;
  htx: JSX.Element;
}

export const exchangeIcons: IExchangeIcons = {
  binance: BinanceIcon,
  okx: OkxIcon,
  bybit: ByBitIcon,
  'crypto-com': CryptoComIcon,
  gateio: GateIoIcon,
  bitmart: BitmartIcon,
  kucoin: KucoinIcon,
  htx: HtxIcon,
};
