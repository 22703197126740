import dayjs from 'dayjs';
import { Order } from 'pages/trading-terminal-page/trading-chart/charting_library/charting_library';
import { getBotDealsFromTerminal } from '../api/get-bot-deals-from-terminal';
import { getBotDealsUuids } from '../api/get-bot-deals-uuids';
import { getPublicBotDeals } from '../api/get-public-bot-deals';

export interface ActiveDeal {
  exchangeAccountUuid: string;
  smartTradeUuid: string;
  status: string;
  baseSymbol: string;
  quoteSymbol: string;
  sideFirstStep: string;
  createdDate: string;
  lastChangeDate: string;
  finishedDate: string | null;
  originalBase: string;
  originalQuote: string;
  executedBaseFirst: string;
  executedQuoteFirst: string;
  executedBaseSecond: string;
  executedQuoteSecond: string;
  returnPercent: number | null;
  returnBase: number | null;
  returnQuote: number | null;
  currentExchangePrice: number | null;
  orders: Order[];
  botUuid: string;
}

export const fetchAllDeals = async (botUuid: string) => {
  const botDeals = await getBotDealsUuids({
    botUuid,
  });
  const smartTradeUuids = botDeals.map(deal => deal.smartTradeUuid);
  
  const smartTrades = await getBotDealsFromTerminal({
    smartTradeUuids, 
  });

  return smartTrades as unknown as ActiveDeal[];
};

export const fetchPublicBotDeals = async (botUuid: string, tradingHistoryDates?: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => {
  const botDeals = await getPublicBotDeals(botUuid, tradingHistoryDates);
  
  return botDeals as unknown as ActiveDeal[];
};
