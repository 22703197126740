// @ts-nocheck

import axios from 'axios';
import {
  adaptBinanceSymbol,
  adaptBybitSymbol,
  adaptCryptoComSymbol,
  adaptGateIOSymbol,
  adaptOkxSymbol,
} from 'pages/trading-terminal-page/trading-chart/helpers/adapt-symbols';

export async function fetchBinanceExchangeInfo() {
  const response = await axios.get('https://api.binance.com/api/v3/exchangeInfo');
  return response.data.symbols
    .filter((symbol) => symbol.status === 'TRADING')
    .map(adaptBinanceSymbol);
}

export async function fetchBybitExchangeInfo() {
  const response = await axios.get('https://api.bybit.com/v5/market/instruments-info?category=spot');
  return response.data.result.list
    .filter((symbol) => symbol.status === 'Trading')
    .map(adaptBybitSymbol);
}

export async function fetchOkxExchangeInfo() {
  const response = await axios.get('https://www.okx.com/api/v5/public/instruments?instType=SPOT');
  return response.data.data
    .filter((symbol) => symbol.state === 'live')
    .map(adaptOkxSymbol);
}

export async function fetchGateIOExchangeInfo() {
  const BASE_URL = window.location.origin;
  const targetUrl = `${BASE_URL}/gateio-api/api/v4/spot/currency_pairs`;

  try {
    const response = await axios.get(targetUrl);
    return response.data
      .filter((symbol) => symbol.trade_status === 'tradable')
      .map(adaptGateIOSymbol);
  } catch (error) {
    console.error('Error getting data from Gate.io:', error);
    throw error;
  }
}

export async function fetchCryptoComExchangeInfo() {
  const response = await axios.get('https://api.crypto.com/exchange/v1/public/get-instruments');
  return response.data.result.data
    .filter((symbol) => symbol.inst_type === 'CCY_PAIR' && symbol.tradable === true)
    .map(adaptCryptoComSymbol);
}
